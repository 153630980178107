<template>
  <div>
    <div v-if="dis == 99">
      <success></success>
    </div>
    <div v-else-if="dis == 1">
      <add-list :group="formlist" :ruleForm="grouplist" @changedis="changedis"></add-list>
    </div>
    <div v-else class="mt15">
      <div v-if="datalist.title!=null">
        <van-row>
          <van-col span="16" offset="1">{{datalist.title}}</van-col>
        </van-row>
        <div class="line"></div>
        <van-form @submit="onSubmit" @failed="failSub">
          <div v-for="(item,key) in datalist.groups" :key="key" class="mt20">
            <van-row class="mb10">
              <van-col span="19" offset="1">{{item.title}}</van-col>
              <van-col span="4" v-if="item.type == 'list'" @click="addData(item)">
                <van-icon name="add" color="#1989F0"/><span style="color: #1989f0">添加</span>
              </van-col>
            </van-row>
            <div v-if="item.type == 'data'">
              <form-display :group="item.fields" :ruleForm="ruleForm"></form-display>
            </div>
            <div v-if="item.type == 'list' && listinfo.length != 0">
              <div v-for="(it,ke) in listinfo" :key="ke" class="mt10 font13">
                <van-row>
                  <van-col span="20" offset="1" class="mt1" v-for="ite in item.fields" :key="ite.name" @click="toUpdate(item,ke,it)">
                    <span class="mr10">{{ ite.title }}：</span>
                    <span  class="" v-if="ite.type=='select'">{{ it[ite.name].label }}</span>
                    <span  class="" v-else-if="ite.type=='mutiselect'">
                    <span v-for="(muti,k) in it[ite.name]" :key="k" >{{ muti.label }}，</span>
                  </span>
                    <span  class="" v-else>{{ it[ite.name] }}</span>
                  </van-col>
                  <van-col span="3" @click="deleteItem(it,ke)" class="col19">删除</van-col>
                </van-row>

              </div>
            </div>
            <div class="line"></div>
          </div>
          <div style="margin: 16px;">
            <van-button round block type="info" native-type="submit">提交</van-button>
          </div>
        </van-form>
      </div>
    </div>
  </div>
</template>

<script>
import qs from "qs";
import {getEditData, saveShareData,} from "@/api/api";
import {Toast} from "vant";
import addList from "@/components/addList";
import formDisplay from "@/components/formDisplay";
import mixins from '@/mixins';
import success from "@/components/success";

export default {
  name: "editshare",
  mixins: [mixins],
  data(){
    return{
      imgUrl: "http://bullpm-1253367923.cos.ap-chengdu.myqcloud.com",
      imgCompress: "?imageMogr2/thumbnail/!70p",
      id: "",
      sign: "",
      datalist: [],
      datainfo: {},
      formlist: [],
      grouplist: [],
      dis: 0,
      listinfo: [],
      ruleForm: {},
      list:{
        field:""
      },
      currentDate: new Date(),
      currentTime:"",
      currentDateTime: new Date(),
      companyId: "",

    }
  },
  components:{
    addList,
    formDisplay,
    success,
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData(){
      /*let href = "https://sixu.work/pubedit?id=fad7f1a05ec4455aa6c8ea4bc54ff000&sign=91190592a8c80d01f42eaf7b08f4a3ec"
      if (href.indexOf("id") != -1) {
        this.id = qs.parse(href.split("#")[0].split("?")[1]).id;
        this.sign = qs.parse(href.split("#")[0].split("?")[1]).sign;
      }*/
      if (window.location.href.indexOf("id") != -1){
        this.id = qs.parse(window.location.href.split("#")[0].split("?")[1]).id;
        this.sign = qs.parse(window.location.href.split("#")[0].split("?")[1]).sign
      }
      if (this.id) {
        getEditData(this.id).then(res => {
          //console.log("share",res);
          if (res != null && res.data != null && res.data.content != null) {
            //console.log('contents', res);
            let contents = res.data.content;
            this.companyId = contents.form.companyId
            this.datalist = JSON.parse(contents.form.formDef);
            //console.log('form', this.datalist);
            let groups = this.datalist.groups;
            if (groups!=null&&groups!=''&&groups.length!=0){
              this.setRuleForm(this.list);
            }
            if(contents.data!=null&&contents.data!=''){
              this.datainfo = contents.data;
              for (let i in this.datalist.groups) {
                let item = this.datalist.groups[i];
                for (let j in item.fields) {
                  let items = item.fields[j];
                  /*if (items.type == 'select' || items.type == 'mutiselect'){
                    if (items.source.data.length != 0){
                      items.disable = true;
                    }
                  }*/
                  if (items.type == 'img') {//当有img时，会组装img路径如下所示
                    if (this.datainfo[items.name] != null && this.datainfo[items.name] != '') {
                      this.datainfo[items.name].list = [];
                      for (let k in this.datainfo[items.name]) {
                        let d = this.datainfo[items.name][k];
                        if (d.fileName != null && d.fileName != '') {
                          this.datainfo[items.name].list.push(this.imgUrl + "/" + this.companyId + "/" + d.fileName + this.imgCompress);
                        }
                      }
                    }
                  }
                  if (items.type == 'file') {
                    if (this.datainfo[items.name] != null && this.datainfo[items.name] != '') {
                      this.datainfo[items.name].list = [];
                      for (let k in this.datainfo[items.name]) {
                        let d = this.datainfo[items.name][k];
                        if (d.fileName != null && d.fileName != '') {
                          this.datainfo[items.name].list.push(this.imgUrl + "/" + this.companyId + "/" + d.fileName);
                        }
                      }
                    }
                  }
                }

              }
              this.setRuleForm(this.list);
            }
          } else {
            Toast.fail(res.data.errorMsg);
          }
        })
      }
    },
    //赋值ruleForm
    setRuleForm(vdata){
      let data={};//
      if (this.datalist.groups){
        for(let i in this.datalist.groups){
          let group=this.datalist.groups[i];
          //data[group.name]=group.title;
          if (group.fields){
            if(group.type=="data"||group.type == 'list'){
              for (let j in group.fields){
                let item = group.fields[j];
                if (item.type == "date") {
                  if (item.source.defaultype == '1'){
                    data[item.name] = item.source.default;
                  }else if (item.source.defaultype == '0'){
                    data[item.name] = this.formatDate(this.currentDate);
                    //console.log('1',data[item.name])
                  }else {
                    data[item.name]=vdata[item.name];
                  }
                }else if (item.type == "time") {
                  if (item.source.defaultype == '1'){
                    data[item.name] = item.source.default;
                  }else if (item.source.defaultype == '0'){
                    data[item.name] = this.currentTime;
                  }else {
                    data[item.name]=vdata[item.name];
                  }
                }else if (item.type == "datetime") {
                  if (item.source.defaultype == '1'){
                    data[item.name] = item.source.default;
                  }else if (item.source.defaultype == '0'){
                    data[item.name] = this.formatDateTime(this.currentDateTime);
                  }else {
                    data[item.name]=vdata[item.name];
                  }
                }else if (item.type == 'txt' || item.type == 'decimal' || item.type == 'number' || item.type == 'phone'){
                  if (item.source.default !=null && item.source.default !=''){
                    data[item.name] = item.source.default;
                  }else {
                    data[item.name]=vdata[item.name];
                  }
                }else {
                  data[item.name]=vdata[item.name];
                }
                if (this.datainfo!=null&&this.datainfo!=''&&this.datainfo.dataId!=null){
                  let k = item.name;
                  if (item.type == "date"){
                    data[item.name] = this.datainfo[k];
                  }else if (item.type == "select") {
                    if (this.datainfo[k]!=null&&this.datainfo[k]!=''){
                      if (item.source.data.length == 0){
                        data[item.name] = this.datainfo[k];
                      }else {
                        data[item.name] = this.datainfo[k].value;
                      }
                    }
                  }else if (item.type == "mutiselect") {
                    if (item.source.data.length == 0){
                      data[item.name] = this.datainfo[k];
                    }
                    else {
                      data[item.name] = [];
                      for (let aa in this.datainfo[k]){
                        data[item.name].push(this.datainfo[k][aa].value)
                      }
                    }
                  }else if (item.type == "time") {
                    data[item.name] = this.datainfo[k];
                  }else if (item.type == "datetime") {
                    data[item.name] = this.datainfo[k];
                  }else {
                    data[item.name] = this.datainfo[k];
                  }
                }
              }
            }
          }
          if (group.type == 'list'){
            if (this.datainfo[group.name]){
              this.listinfo = this.datainfo[group.name];
              this.listinfos = this.listinfo;
              localStorage.removeItem('listinfo')
              const data = this.listinfos;
              const datas = JSON.stringify(data);
              localStorage.setItem('listinfo',datas);
            }
          }
        }
      }
      this.ruleForm = data;
      //this.errmsg = data;
      //console.log("shujusss",this.ruleForm);
    },
    addData(item){
      this.formlist = [];
      this.formlist = item;
      this.setRuleForm(this.list);
      this.grouplist = {};
      this.grouplist = this.ruleForm;
      this.nowkey = null;
      this.dis = 1;
    },
    //获取添加的列表的数据
    getInfo(){
      if (localStorage.getItem('listinfo')!=null && localStorage.getItem('listinfo')!=undefined){
        this.listinfo = JSON.parse(localStorage.getItem('listinfo'));
        this.listinfos = JSON.parse(localStorage.getItem('listinfo'));
        for (let n in this.listinfo){
          let info = this.listinfo[n];
          for (let k in this.datalist.groups){
            let group = this.datalist.groups[k];
            if (group.type == 'list'){
              for (let k in group.fields){
                let sou = group.fields[k];
                if (sou.type == 'select'){
                  if (info[sou.name]){
                    let da = info[sou.name];
                    let dat = sou.source.data;
                    for (let m in dat){
                      if (da == dat[m].value){
                        info[sou.name]= dat[m];
                      }
                    }
                  }
                }else if (sou.type == 'mutiselect'){
                  let datas = [];
                  if (info[sou.name]){
                    for (let p in info[sou.name]){
                      let da = info[sou.name][p];
                      if (da.label){
                        da = info[sou.name][p];
                      }else {
                        let dat = sou.source.data;
                        for (let d in dat){
                          if (da == dat[d].value){
                            datas.push(dat[d]);
                          }
                        }
                      }
                    }
                    info[sou.name] = datas;
                  }
                }
              }
            }
          }
        }
      }
      //console.log("list",this.listinfo);
    },
    toUpdate(item,ke,itt){
      this.formlist = [];
      this.formlist = item;
      this.grouplist = {};
      let i = itt;
      for (let z in item.fields){
        let its = item.fields[z];
        if (its.type == 'select'){
          if (i[its.name]){
            i[its.name] = i[its.name].value;
          }
        }else if (its.type == 'mutiselect'){
          if (i[its.name].length != 0){
            for (let a in i[its.name]){
              let it = i[its.name][a];
              i[its.name][a] = it.value;
            }
          }
        }
      }
      this.grouplist = i;
      let da = {key: ke}
      this.$store.commit('addlist/CLEAR_ADDINFO');
      this.$store.commit('addlist/SET_ADDINFO',da);
      //console.log("thke",this.nowkey)
      this.dis = 1;
    },
    deleteItem(it,ke){
      this.listinfo.splice(ke,1);
      this.listinfos.splice(ke,1);
      let data = JSON.stringify(this.listinfos)
      localStorage.removeItem('listinfo');
      localStorage.setItem('listinfo',data);
    },
    onSubmit(){//提交表单
      for(let t in this.datalist.groups){
        let grou = this.datalist.groups[t];
        if (grou.type == 'list'){
          this.ruleForm[grou.name] = [];
          this.ruleForm[grou.name] = this.listinfo;
          for (let l in grou.fields){
            let lit = grou.fields[l];
            delete this.ruleForm[lit.name];
          }
        }else {
          for(let n in grou.fields){
            let items= grou.fields[n];
            if (items.type=="select"){
              if (this.ruleForm[items.name]){
                let valu = this.ruleForm[items.name];
                let datas = items.source.data
                if (datas!=null&&datas.length>0){
                  for (let m in datas){
                    let val=datas[m];
                    if (val.value==valu){
                      this.ruleForm[items.name]=val;
                    }
                  }
                }
              }
            }
            if (items.type=="mutiselect"){
              let values = [];
              if (this.ruleForm[items.name]){
                for(let i in this.ruleForm[items.name]){
                  let val = this.ruleForm[items.name][i];
                  if (items.source.data!=null&&items.source.data!=''&&items.source.data.length!=0){
                    for (let j in items.source.data){
                      let vall = items.source.data[j];
                      if (val == vall.value){
                        values.push(vall) ;
                      }
                    }
                  }
                }
              }
              this.ruleForm[items.name] = values
            }
          }
        }
      }
      //console.log("ruleForm",this.ruleForm);
      saveShareData(this.id,this.ruleForm).then(res => {
        if (res!=null&&res.data!=null&&res.data.content!=null){
          if (res.data.content==1){
            Toast.success('提交成功');
            this.lowering(this.ruleForm);
            //window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx5ef0e4fab3c1525d&redirect_uri=https%3A%2F%2Fsixu.work%2Ftask&response_type=code&scope=snsapi_userinfo&state="+this.state+"#wechat_redirect"
            localStorage.removeItem('listinfo');
            this.dis = 99;
          }
        }else {
          Toast.fail(res.data.errorMsg);
          this.lowering(this.ruleForm);
        }
      })
    },
    lowering(da){
      for(let t in this.datalist.groups){
        let grou = this.datalist.groups[t];
        for(let n in grou.fields){
          let items= grou.fields[n];
          if (items.type=="select"){
            //console.log("111",da);
            if (da[items.name] != null && da[items.name] != ''){
              //console.log("333")
              da[items.name] = da[items.name].value
              //console.log("aaa",da[items.name])
            }
          }
          if (items.type=="mutiselect"){
            let values = [];
            if (da[items.name]){
              for(let i in da[items.name]){
                values.push(da[items.name][i].value);
              }
            }
            da[items.name] = values
          }
        }
      }
      //console.log("daaaa",da)
      //return da;
    },
    failSub(){
      Toast("请检查必填项是否全部填写！")
    },
    changedis(d){
      this.dis = d;
      this.getInfo();
    }
  },
}
</script>

<style scoped>
.wid{width: 100%;}
.mt1{margin-top: 5px;}
.mb1{margin-bottom: 5px;}
.mt3{margin-top: 3vh;}
.mt4{margin-top: 4vh;}
.mt5{margin-top: 5vh;}
.mt20{margin-top: 2vh;}
.mt10{margin-top: 10px;}
.ml15{margin-left: 15px;}
.mb20{margin-bottom: 2vh;}
.mt15{margin-top: 15px;}
.mb10{margin-bottom: 1vh;}
.font13{font-size: 13px;}
.line1{border-top: #f2f2f2 solid 1px;width: 88vw;margin: 8px auto;}
.line{border-top: #f2f2f2 solid 1px;width: 92vw;margin: 20px auto;}
.mr{margin-right: 18vw;}
.mr10{margin-right: 10px;}
.mright{text-align:right;}
.mcenter{text-align: center;}
.btn{text-align: center;margin: 4vh auto;}
.txt{vertical-align: middle;}
/deep/ .van-dropdown-menu{box-shadow:0 0 0 #f2f2f2!important;}
/deep/ .van-cell{padding: 4px 16px;}
.col19{color: #1989f0;}
</style>